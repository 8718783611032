import axios from 'axios'
import showToastify from './toastify'; 


export default function contactForm(){
	const btnFormContato = document.querySelector('.btn-contact')
	const formContato = document.querySelector('#enter-in-contact')

	
 
	

	btnFormContato && btnFormContato.addEventListener('click', function (event) {
		//console.log("teste2")
		
		
		event.preventDefault();
		//disabledBtn()

		
		
			function FormFunction() {

				let name = document.querySelector('#enter-in-contact input[name=name]');
				let email = document.querySelector('#enter-in-contact input[name=email]');
   		  let message = document.querySelector('#enter-in-contact textarea[name=description]');
				let form_data = {}


				//console.log(typeof email.value);
				//console.log(typeof message.value);


				if (email.value === '' || message.value === '' || name.value == '' ) {
					//console.log("Erro para aqui")
					
					return;
				} 
				else {
							
					form_data = {
						email: document.querySelector('#enter-in-contact input[name=email]').value,
									
						nome: document.querySelector('#enter-in-contact input[name=name]').value,
								
						mensagem: document.querySelector('#enter-in-contact textarea[name=description]').value									
					}

				}

				return form_data

			}


			FormFunction()


			console.log("EU", FormFunction())
			

		
				
		if (FormFunction() != undefined) {
			const url = "/fale-conosco/"
		axios.post(url, FormFunction())
			.then((response) => {
				console.log(response)
				
			})
			.catch((response) => console.log(response));

			
			formContato.reset();
			showToastify("Email enviado com sucesso!")

		} else {
			showToastify("Preencha todos os campos")
		}

		


	});

	
	// function disabledBtn() {
	// 	btnFormContato.disabled = true
	// 	btnFormContato.classList.add("disabled")
	// }

	

	
	
}




