import Swiper from "swiper"






const Our_clients = () => {

	//console.log("eu aqui")
	
	const swipercont1 = new Swiper(".swiper-container-2", {
		direction:'horizontal',
		loop: true, // Torna o carrossel infinito
		slidesPerView: 3, // Mostra três slides por vez
		spaceBetween: 30, // Espaço entre os slides
		speed: 1260, // Suaviza a transição
   	effect: 'slide', // Escolhe o efeito de transição
		autoplay: {
			delay: 200,
			disableOnInteraction: true,
		},
	});


	const swipercont2 = new Swiper(".swiper-container-3", {
		direction:'horizontal',
		
		loop: true, 
		slidesPerView: 3, 
		spaceBetween: 30, 
		speed: 1260, 
   	effect: 'slide', 
		autoplay: {
			delay: 200,
			reverseDirection: true,
		},
	});
	 
	 
}




export default Our_clients
