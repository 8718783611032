import Swiper from 'swiper'
import { find } from 'utils/dom'
import Our_Services from '../sections/home_our_services'
import Our_socios from '../sections/home_our_socios'
import Our_clients from '../sections/home_our_clients'
import showToastify from '../utils/toastify'
import animaAoScroll from '../animation/animaAoScroll'
import  gsap  from 'gsap'
import ScrollTrigger from 'gsap'
//import animaAoScroll2 from '../utils/animaScroll'

export default function () {
	const Home = find("[js-page='home']")
	if (!Home) return

	const target = document.querySelectorAll('[data-anime]')
	const animationClass = 'animate'

	function animescroll() {
		//console.log("teste")
		const windowTop = window.scrollY
		//console.log(windowTop)
		target.forEach((element) => {
			if (windowTop > element.offsetTop) {
				element.classList.add(animationClass)
			}

			console.log(element.offsetTop)
		})
	}

	window.addEventListener('scroll', function () {
		animescroll()
	})

	// const socios = new Swiper(".swiper", {
	// 	cssMode: true,
	// 	loop: true,
	// 	navigation: {
	// 		nextEl: ".swiper-button-next",
	// 		prevEl: ".swiper-button-prev",
	// 	},
	// 	pagination: {
	// 		el: ".swiper-pagination",
	// 	},
	// 	keyboard: true,
	// });

	// const swiper = new Swiper('.swiper', {
	// 	slidesPerView: 3,
	// 	spaceBetween: 30,
	// 	autoplay: {
	// 		delay: 1,
	// 		disableOnInteraction: false,
	// 	},
	// 	direction: 'horizontal',
	// 	loop: true,
	// });

	// Inicialize o Swiper
	// 	document.addEventListener('DOMContentLoaded', (event) => {
	// 		console.log("testando")
	// 		var swiper = new Swiper('.swiper-container', {

	// 				direction: 'horizontal',
	// 				loop: true,
	// 				autoplay: {
	// 						delay: 10,
	// 				},
	// 				speed: 200,
	// 		});
	// });

	// Selecione todos os containers do Swiper
	const swiperContainers = document.querySelectorAll('.swiper-container')

	// antigo
	// var headers = ['midiaDigital', 'marketingInfluencia', 'producaoExecutiva', 'estrategicoDivulgacao'];
	// var currentIndex = 0;

	// function cycleHeaders() {
	// 	var currentHeader = document.getElementById(headers[currentIndex]);
	// 	var nextHeader = document.getElementById(headers[(currentIndex + 1) % headers.length]);

	// 	currentHeader.style.opacity = '0';
	// 	setTimeout(function() {

	// 		currentHeader.style.display = 'none';
	// 		nextHeader.style.display = 'block';
	// 		nextHeader.style.opacity = '1';
	// 	}, 2000);

	// 	currentIndex = (currentIndex + 1) % headers.length;
	// }

	// setInterval(cycleHeaders, 2000);

	// var headers = ['midiaDigital', 'marketingInfluencia', 'producaoExecutiva', 'estrategicoDivulgacao'];
	// var currentIndex = 0;

	// function cycleHeaders() {
	// 	var currentHeader = document.getElementById(headers[currentIndex]);
	// 	var nextHeader = document.getElementById(headers[(currentIndex + 1) % headers.length]);

	// 	setTimeout(function() {

	// 		currentHeader.style.display = 'none';
	// 		nextHeader.style.display = 'block';
	// 		window.introAnima.play();

	// 	}, 2000);

	// 	currentIndex = (currentIndex + 1) % headers.length;

	// 	console.log( nextHeader)

	//  }

	//  setInterval(cycleHeaders, 2000);

	// var headers = ['midiaDigital', 'marketingInfluencia', 'producaoExecutiva', 'estrategicoDivulgacao'];
	// var currentIndex = 0;

	// function cycleHeaders() {
	// 	var currentHeader = document.getElementById(headers[currentIndex]);
	// 	var nextHeader = document.getElementById(headers[(currentIndex + 1) % headers.length]);

	// 	//currentHeader.style.opacity = '0';
	// 	setTimeout(function() {

	// 			headers.forEach()

	// 		// currentHeader.style.display = 'none';
	// 		// nextHeader.style.display = 'block';
	// 		// //nextHeader.style.opacity = '1';
	// 		// //window.introAnima.resume();
	// 		// window.introAnima.repeat(-1)
	// 		// console.log("Eu estou aq")

	// 	}, 2300);

	// 	//console.log(headers.length);

	// 	currentIndex = (currentIndex + 1) % headers.length;
	// }

	//setInterval(cycleHeaders, 2100);

	

	// function esconderImagemDeFundo(elemento) {
		
	// 	gsap.to(elemento, ".bg-[rgba(117,10,56,1)]", {opacity: 0});
	
		
	// 	gsap.set(elemento, {backgroundImage: "none"});
	// }


	// const animacard = find('[animacard]');
	// esconderImagemDeFundo(animacard);


	document.querySelector('#formularioLink').addEventListener('click', function(event){
		event.preventDefault();
		event.stopPropagation();
		document.getElementById('banner').classList.remove("hidden")
	})





	document.body.addEventListener('click', function(event) {
		if (!event.target.closest('#banner')) {
			 document.getElementById('banner').classList.add("hidden")
		}
	});



	//  Baner socias

	

	const isMobileScreen = () => window.innerWidth <= 768;
	

	document.querySelector('#socia1').addEventListener('click', function(event){
		if (isMobileScreen()) {
			event.preventDefault();
			event.stopPropagation();
			return
		}

		event.preventDefault();
		event.stopPropagation();
		document.getElementById('bannerSocias1').classList.remove("hidden")
		document.getElementById('socios').scrollIntoView({ behavior: 'smooth' })
		document.body.classList.add("no-scroll");
	})

	document.querySelector('#bannerSocias1').addEventListener('click', function() {

		event.stopPropagation();
	})

	document.body.addEventListener('click', function(event) {
		if (isMobileScreen()) {
			event.preventDefault();
			event.stopPropagation();
			return
		}

		

		if (!event.target.closest('#bannerSocias1')) {
			 document.getElementById('bannerSocias1').classList.add("hidden")
			 document.body.classList.remove("no-scroll");
		}
	});

	document.querySelector('#fechabaner1').addEventListener('click', function(event) {
		
    if (isMobileScreen()) {
        event.preventDefault();
        event.stopPropagation();
        return;
    }

    
    
			document.getElementById('bannerSocias1').classList.add("hidden")
			document.body.classList.remove("no-scroll");
	 
    
});




	document.querySelector('#socia2').addEventListener('click', function(event){
		if (isMobileScreen()) {
			event.preventDefault();
			event.stopPropagation();
			return
		}

		event.preventDefault();
		event.stopPropagation();
		document.getElementById('bannerSocias2').classList.remove("hidden")
		document.getElementById('socios').scrollIntoView({ behavior: 'smooth' })
		document.body.classList.add("no-scroll");
	})

	document.querySelector('#bannerSocias2').addEventListener('click', function() {

		event.stopPropagation();
	})

	document.body.addEventListener('click', function(event) {
		if (isMobileScreen()) {
			event.preventDefault();
			event.stopPropagation();
			return
		}

		if (!event.target.closest('#bannerSocias2')) {
			 document.getElementById('bannerSocias2').classList.add("hidden")
			 document.body.classList.remove("no-scroll");
		}
	});

	document.querySelector('#fechabaner2').addEventListener('click', function(event) {
		
    if (isMobileScreen()) {
        event.preventDefault();
        event.stopPropagation();
        return;
    }

    
    
			document.getElementById('bannerSocias2').classList.add("hidden")
			document.body.classList.remove("no-scroll");
	 
    
});





	document.querySelector('#socia3').addEventListener('click', function(event){
		if (isMobileScreen()) {
			event.preventDefault();
			event.stopPropagation();
			return
		}

		event.preventDefault();
		event.stopPropagation();
		document.getElementById('bannerSocias3').classList.remove("hidden")
		document.getElementById('socios').scrollIntoView({ behavior: 'smooth' })
		document.body.classList.add("no-scroll");
	})



	document.querySelector('#bannerSocias3').addEventListener('click', function() {

		event.stopPropagation();
	})

	document.body.addEventListener('click', function(event) {
			
		if (isMobileScreen()) {
			event.preventDefault();
			event.stopPropagation();
			return
		}

		
    

		if (!event.target.closest('#bannerSocias3')) {
			 document.getElementById('bannerSocias3').classList.add("hidden")
			 document.body.classList.remove("no-scroll");
		}
	});

	document.querySelector('#fechabaner3').addEventListener('click', function(event) {
		
    if (isMobileScreen()) {
        event.preventDefault();
        event.stopPropagation();
        return;
    }

    
    
			document.getElementById('bannerSocias3').classList.add("hidden")
			document.body.classList.remove("no-scroll");
	 
    
});
	



	gsap.to("#elementBG", {
		backgroundColor: "#CEEAFB",
		duration: 0.4,
		delay: 1.2,
		scrollTrigger: {
		 trigger: "#elementBG",
		 start: "top 40%", 
		 end: "bottom bottom", 
		 scrub: true, 
		
		},
	});


	
	


	gsap.fromTo("#GrupCard1 svg", {
		delay: 1,
		opacity: 0,
		xPercent: -100,
		scale:0, 
	}, {
		opacity: 1,
		duration: 0.4,
		delay: 0.6,
		scale:1,
		xPercent: 100,
		ease: "power1.inOut",
		scrollTrigger: {
			trigger: "#GrupCard1",
			start: "top 70%", 
			end: "bottom bottom", 
			scrub: false, 
			
		}
	});


	gsap.fromTo("#GrupCard1 h1", {
		delay: 1,
		opacity: 0,
		xPercent: -100,
	}, {
		opacity: 1,
		duration: 0.4,
		delay: 0.2,
		xPercent: 1,
		ease: "power1.inOut",
		scrollTrigger: {
			trigger: "#GrupCard1",
			start: "top 70%", 
			end: "bottom bottom", 
			scrub:  false, 
			
		}
	});

	gsap.fromTo("#GrupCard1 p", {
		delay: 1,
		opacity: 0,
		xPercent: -100,
	}, {
		opacity: 1,
		duration: 0.4,
		delay: 0.4,
		xPercent: 1,
		ease: "power1.inOut",
		scrollTrigger: {
			trigger: "#GrupCard1",
			start: "top 70%", 
			end: "bottom bottom", 
			scrub: false, 
			
		}
	});


	// ---------------------------- 2card


	gsap.fromTo("#GrupCard2 svg", {
		delay: 1,
		opacity: 0,
		xPercent: -100,
		scale:0, 
	}, {
		opacity: 1,
		duration: 0.4,
		delay: 0.7,
		scale:1,
		xPercent: 1,
		ease: "power1.inOut",
		scrollTrigger: {
			trigger: "#GrupCard1",
			start: "top 70%", 
			end: "bottom bottom", 
			scrub: false, 
			
		}
	});

	gsap.fromTo("#GrupCard2 h1", {
		delay: 1,
		opacity: 0,
		xPercent: -100,
	}, {
		opacity: 1,
		duration: 0.4,
		delay: 0.3,
		xPercent: 1,
		ease: "power1.inOut",
		scrollTrigger: {
			trigger: "#GrupCard1",
			start: "top 70%", 
			end: "bottom bottom", 
			scrub: false, 
			
		}
	});

	gsap.fromTo("#GrupCard2 p", {
		delay: 1,
		opacity: 0,
		xPercent: -100,
	}, {
		opacity: 1,
		duration: 0.4,
		delay: 0.5,
		xPercent: 1,
		ease: "power1.inOut",
		scrollTrigger: {
			trigger: "#GrupCard1",
			start: "top 70%", 
			end: "bottom bottom", 
			scrub: false, 
			
		}
	});



	// ---------------------------- 3card



	gsap.fromTo("#GrupCard3 svg", {
		delay: 1,
		opacity: 0,
		xPercent: -100,
		scale:0, 
	}, {
		opacity: 1,
		duration: 0.4,
		delay: 0.7,
		scale:1,
		xPercent: 1,
		ease: "power1.inOut",
		scrollTrigger: {
			trigger: "#GrupCard1",
			start: "top 70%", 
			end: "bottom bottom", 
			scrub: false, 
		}	
	});

	gsap.fromTo("#GrupCard3 h1", {
		delay: 1,
		opacity: 0,
		xPercent: -100,
	}, {
		opacity: 1,
		duration: 0.4,
		delay: 0.3,
		xPercent: 1,
		ease: "power1.inOut",
		scrollTrigger: {
			trigger: "#GrupCard1",
			start: "top 70%", 
			end: "bottom bottom", 
			scrub: false, 
			
		}
	});


	gsap.fromTo("#GrupCard3 p", {
		delay: 1,
		opacity: 0,
		xPercent: -100,
	}, {
		opacity: 1,
		duration: 0.4,
		delay: 0.5,
		xPercent: 1,
		ease: "power1.inOut",
		scrollTrigger: {
			trigger: "#GrupCard1",
			start: "top 70%", 
			end: "bottom bottom", 
			scrub: false, 
			
		}
	});

	

	// function animaColor () {


	// 	const inovacao = document.querySelector('#cardInovacao')
	// 	const comunicacao = document.querySelector('#cardComunicacao')
	// 	const crecimento = document.querySelector('#cardCrecimento')



		
	// 		gsap.to(inovacao, {duration: 1, backgroundColor: "#FF743B"});
	// 		gsap.to(crecimento, {duration: 1, backgroundColor: "#ff9d02"})
	// 		gsap.to(comunicacao, {duration: 1, delay: 1, backgroundColor: "#FF743B"})
	// 		gsap.to(inovacao, {duration: 1, delay: 1, backgroundColor: "#2ba3ed"});
	// 		gsap.to(crecimento, {duration: 1, delay: 2, backgroundColor: "#2ba3ed"})
	// 		gsap.to(inovacao, {duration: 1, delay: 2, backgroundColor: "#ff9d02"});
	// 		gsap.to(comunicacao, {duration: 1, delay: 3, backgroundColor: "#2ba3ed"})
	// 		gsap.to(crecimento, {duration: 1,  delay: 3, backgroundColor: "#FF743B"})
			
	// 		reiniciaAnima()

		



	// 	let removed = false; 

	// 			function reiniciaAnima() {
	// 				const elements = document.querySelectorAll('[data-timeline="cardBol"]');

	// 				elements.forEach(element => {
	// 						const toData = JSON.parse(element.getAttribute('data-to'));
	// 						const fromData = JSON.parse(element.getAttribute('data-from'));
							
	// 						const tl = gsap.timeline({
	// 								onComplete: () => {
	// 										if (!removed) { 
	// 														gsap.to(element, {
	// 																delay: 3,
	// 																xPercent: 100,
	// 																opacity: 0,
	// 																duration: 0.6,
	// 																ease: "power1.in",
	// 																onComplete: () => {
	// 																	removed = true; 
	// 																}
	// 														});													 
	// 										} else { 	
												
													 
	// 												gsap.set(element, {clearProps: "all"}); 
	// 												gsap.fromTo(element, {
	// 													delay: 1,
	// 													opacity: 0,
	// 													xPercent: 10, 
	// 											 }, {
	// 													opacity: 1,
	// 													xPercent: 0,
	// 													duration: 0.6, 
	// 													ease: "power1.inOut", 
	// 											 });
												 
											 
	// 										}
	// 								}
	// 						});

	// 						setTimeout(() => tl.fromTo(element, fromData, {...toData,}), 3500);
	// 				});
	// 			}

		



		









				

	// }



	// setInterval(animaColor, 5500);



	
	let svg_icon = document.getElementsByClassName("svg_icon")

	console.log("ATras de vc", svg_icon)
	
	  






	



	//novo
	const textIntroWrapper = find('[js-text-intro]')

	// let n = 0

	async function loopReset() {
		const texts = ['Mídia Digital', 'Marketing de Influência', 'Produção Executiva', 'Estratégias de Comunicação']
		const text1 = find('[js-text-1]', textIntroWrapper)
		const text2 = find('[js-text-2]', textIntroWrapper)

		let count = 0
		text1.innerText = texts[count]

		const counter = () => {
			if (count === 3) {
				count = 0
			} else {
				count++
			}
		}

		gsap.set(text1, { yPercent: 0, opacity: 1 })
		gsap.set(text2, { yPercent: -110, opacity: 0 })

		const duration = 0.5
		const ease = 'none'
		const delay = 1.6


		const fillText = (variable) => {
			counter()
			variable.innerText = texts[count]
		}

		const tl = gsap
			.timeline({ overwrite: true, delay: 1.2, repeat: -1, paused: true })
			// texto 1 desceu
			.to(text1, { yPercent: 110, opacity: 0, duration, ease, delay }, '@inicial')
			// texto 2 recebeu o texto
			.call(fillText, [text2], '<')
			// texto 2 ativo
			.to(text2, { yPercent: 0, opacity: 1, duration, ease, delay }, '@inicial')
			// text 1 pulou pra cima
			.set(text1, { yPercent: -110, opacity: 0 })
			// setei texto no texto 1
			.call(fillText, [text1], '<')
			// texto 2 desceu
			.to(text2, { yPercent: 110, opacity: 0, duration, ease, delay }, '@medio')
			// texto 1 ativo
			.to(text1, { yPercent: 0, opacity: 1, duration, ease, delay }, '@medio')

		tl.timeScale(0.9).play()
		window.tlIntro = tl

		// preencher o innertext dos textos
		// setar o texto 2 pra cima
		// setar o text 1 no lugar
		// animar o texto 2 pro lugar ao mesmo tempo que eu animo o texto 1  pra baixo
		// texto 1 jogo pra cima

		// await new Promise((resolve) => setTimeout(resolve, 3000))

		// headersCollections.forEach((item) => {
		// 	//item.classList.remove('HiddenOpacity')
		// 	item.classList.add('hidden')
		// 	window.introAnima.kill()
		// })
		// if (n >= 0 && n < headersCollections.length) {
		// 	headersCollections[n].classList.remove('hidden')
		// 	//headersCollections[n].classList.add('HiddenOpacity')
		// 	//headersCollections[n].classList.add('HiddenText')
		// 	window.introAnima.timeScale(0.5).restart(true)
		// 	window.introAnima.resume()
		// }

		// n = (n + 1) % 5
		// console.log(headersCollections[n])
		// loopReset()
	}

	loopReset()

	// setTimeout(()=>{

	//     headersCollections.forEach(item =>{
	// 			headersCollections.forEach(i=>{
	// 				i.classList.add('hidden')
	// 			})
	//         //console.log('Item->',item)
	// 				item.classList.remove('hidden')
	//     })
	// },200)

	// var midia = document.getElementById('midiaDigital');

	// setInterval(() => {
	// 	midia.classList.toggle("hidden")
	// 	window.Intro.repeat(-1)
	// }, 2300);

	//  nav mobile

	var iniciolink = document.getElementsByClassName('inicio-link')
	for (var i = 0; i < iniciolink.length; i++) {
		iniciolink[i].addEventListener('click', function (event) {
			event.preventDefault()
			document.getElementById('principal-header').scrollIntoView({ behavior: 'smooth' })
		})
	}





	// var sobrelinkmobile = document.getElementsByClassName('sobre-link-mobile')
	// for (var i = 0; i < sobrelinkmobile.length; i++) {
	// 	sobrelinkmobile[i].addEventListener('click', function (event) {
	// 		event.preventDefault()
	// 		document.getElementById('sobreNos').scrollIntoView({ behavior: 'smooth' })
	// 	})
	// }


	// var sobrelinkfooter = document.getElementsByClassName('sobre-link-footer')
	// for (var i = 0; i < sobrelinkfooter.length; i++) {
	// 	sobrelinkfooter[i].addEventListener('click', function (event) {
	// 		event.preventDefault()
	// 		document.getElementById('sobreNos').scrollIntoView({ behavior: 'smooth' })
	// 	})
	// }



	var buttons = document.getElementsByClassName('sobre-link')
	for (var i = 0; i < buttons.length; i++) {
		buttons[i].addEventListener('click', function (event) {
			event.preventDefault()
			document.getElementById('sobreNos').scrollIntoView({ behavior: 'smooth' })
		})
	}






	// var servicosLinkmobile = document.getElementsByClassName('servicos-link-mobile')
	// for (var i = 0; i < servicosLinkmobile.length; i++) {
	// 	servicosLinkmobile[i].addEventListener('click', function (event) {
	// 		event.preventDefault()
	// 		document.getElementById('servicos-link').scrollIntoView({ behavior: 'smooth' })
	// 	})
	// }



	// var servicelinkfooter = document.getElementsByClassName('service-link-footer')
	// for (var i = 0; i < servicelinkfooter.length; i++) {
	// 	servicelinkfooter[i].addEventListener('click', function (event) {
	// 		event.preventDefault()
	// 		document.getElementById('servicos-link').scrollIntoView({ behavior: 'smooth' })
	// 	})
	// }



	var servicosLink = document.getElementsByClassName('servicos-link')
	for (var i = 0; i < servicosLink.length; i++) {
		servicosLink[i].addEventListener('click', function (event) {
			event.preventDefault()
			document.getElementById('servicos-link').scrollIntoView({ behavior: 'smooth' })
		})
	}






	// var sociosLinkmobile = document.getElementsByClassName('socios-link-mobile')
	// for (var i = 0; i < sociosLinkmobile.length; i++) {
	// 	sociosLinkmobile[i].addEventListener('click', function (event) {
	// 		event.preventDefault()
	// 		document.getElementById('socios').scrollIntoView({ behavior: 'smooth' })
	// 	})
	// }

	// var socioslinkfooter = document.getElementsByClassName('socios-link-footer')
	// for (var i = 0; i < socioslinkfooter.length; i++) {
	// 	socioslinkfooter[i].addEventListener('click', function (event) {
	// 		event.preventDefault()
	// 		document.getElementById('socios').scrollIntoView({ behavior: 'smooth' })
	// 	})
	// }

	var sociosLink = document.getElementsByClassName('socios-link')
	for (var i = 0; i < sociosLink.length; i++) {
		sociosLink[i].addEventListener('click', function (event) {
			event.preventDefault()
			document.getElementById('socios').scrollIntoView({ behavior: 'smooth' })
		})
	}



	// var clientesLinkmobile = document.getElementsByClassName('clientes-link-mobile')
	// for (var i = 0; i < clientesLinkmobile.length; i++) {
	// 	clientesLinkmobile[i].addEventListener('click', function (event) {
	// 		event.preventDefault()
	// 		document.getElementById('cliente').scrollIntoView({ behavior: 'smooth' })
	// 	})
	// }

	// var clientelinkfooter = document.getElementsByClassName('cliente-link-footer')
	// for (var i = 0; i < clientelinkfooter.length; i++) {
	// 	clientelinkfooter[i].addEventListener('click', function (event) {
	// 		event.preventDefault()
	// 		document.getElementById('cliente').scrollIntoView({ behavior: 'smooth' })
	// 	})
	// }

	var clientesLink = document.getElementsByClassName('clientes-link')
	for (var i = 0; i < clientesLink.length; i++) {
		clientesLink[i].addEventListener('click', function (event) {
			event.preventDefault()
			document.getElementById('cliente').scrollIntoView({ behavior: 'smooth' })
		})
	}




	// var contatoLinkmobile = document.getElementsByClassName('contato-mobile')
	// for (var i = 0; i < contatoLinkmobile.length; i++) {
	// 	contatoLinkmobile[i].addEventListener('click', function (event) {
	// 		event.preventDefault()
	// 		document.getElementById('contato').scrollIntoView({ behavior: 'smooth' })
	// 	})
	// }

	var contatoLink = document.getElementsByClassName('contato')
	for (var i = 0; i < contatoLink.length; i++) {
		contatoLink[i].addEventListener('click', function (event) {
			event.preventDefault()
			document.getElementById('contato').scrollIntoView({ behavior: 'smooth' })
		})
	}

	var contatoFale = document.getElementsByClassName('contato-fale-conosco')
	for (var i = 0; i < contatoFale.length; i++) {
		contatoFale[i].addEventListener('click', function (event) {
			event.preventDefault()
			document.getElementById('contato').scrollIntoView({ behavior: 'smooth' })
		})
	}





	//nav footer

	var iniciolinkfooter = document.getElementsByClassName('inicio-link-footer')
	for (var i = 0; i < iniciolinkfooter.length; i++) {
		iniciolinkfooter[i].addEventListener('click', function (event) {
			event.preventDefault()
			document.getElementById('top').scrollIntoView({ behavior: 'smooth' })
		})
	}

	

	

	

	

	




	

	
	document.getElementById('botao').addEventListener('click', function () {
		document.getElementById('menu').classList.toggle('hidden')
		document.getElementById('meuNav').classList.toggle('bg-[#000000]')
		document.getElementById('meuNav').classList.toggle('bg-[#ffffff]')
		var svg1 = document.getElementById('svg1')
		var svg2 = document.getElementById('svg2')

		svg1.classList.toggle('hidden')
		svg1.classList.toggle('block')
		svg2.classList.toggle('hidden')
		svg2.classList.toggle('block')

		var img1 = document.getElementById('minhaImagem')
		var img2 = document.getElementById('minhaImagem2')

		img1.classList.toggle('hidden')
		img1.classList.toggle('block')
		img2.classList.toggle('hidden')
		img2.classList.toggle('block')
	})

	/** Swiper Inovação | Comunicação | Crescimento */
	const SwiperInovacaoComunicaoCrescimento = new Swiper('.Swiper-Inovacao-Comunicacao-Crescimento', {
		slidesPerView: 2,
		autoplay: {
			delay: 1400,
		},
		speed: 600,
		spaceBetween: 0,
		breakpoints: {
			800: {
				slidesPerView: 3,
			},
			1200: {
				slidesPerView: 5,
			},
		},
	})

	const SwiperInovacao = new Swiper('.SwiperInovacao', {
		slidesPerView: 1.47,

		spaceBetween: 0,
		direction: 'vertical',
		centeredSlides: true,
		speed: 700,
		loop: true,
		autoplay: {
			delay: 3000,
		},
	})

	const SwiperComunicacao = new Swiper('.SwiperComunicacao', {
		slidesPerView: 'auto',
		spaceBetween: 0,
		direction: 'vertical',
		centeredSlides: true,
		speed: 700,
		loop: true,
		autoplay: {
			delay: 3000,
		},
	})




	


	let svg_icon_redes = document.querySelectorAll(".svg_icon_redes")
	let svgs = []
	let svg_icon_redes_visible = document.querySelectorAll(".svg_icon_redes_visible")

	
	for(let i = 0; i < svg_icon_redes.length; i++) {

		svgs.push(svg_icon_redes[i].innerText)

		console.log(svgs)

		

		svg_icon_redes_visible[i].innerHTML = svgs[i];

	}


	



	

	

	
	
	
	
	













	/*** Seção 'Our Services' */
	function init() {
		loopReset()
		Our_Services()
		Our_socios()
		Our_clients()
		animaAoScroll()

		//animaAoScroll2()
	}

	return {
		init,
	}
}
