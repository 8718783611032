import axios from 'axios'
import showToastify from './toastify'; 


export default function contactFormComing(){
	const btnFormContato = document.querySelector('.btn-contact-coming')
	const formContato = document.querySelector('#enter-in-contact-coming')

	
 
	

	btnFormContato && btnFormContato.addEventListener('click', function (event) {
		console.log("teste2")
		
		
		event.preventDefault();
		//disabledBtn()

		
		
			function FormFunction() {

				
				let email = document.querySelector('#enter-in-contact-coming input[name=email]');
   		  
				let form_data = {}


				console.log(typeof email.value);
				


				if (email.value === '') {
					//console.log("Erro para aqui")
					
					return;
				} 
				else {
							
					form_data = {
						email: document.querySelector('#enter-in-contact-coming input[name=email]').value,
									
															
					}

				}

				return form_data

			}


			FormFunction()


			console.log("EU", FormFunction())
			

		
				
		if (FormFunction() != undefined) {
			const url = "/fale-conosco/"
		axios.post(url, FormFunction())
			.then((response) => {
				console.log(response)
				
			})
			.catch((response) => console.log(response));

			
			formContato.reset();
			showToastify("Email enviado com sucesso!")

		} else {
			showToastify("Preencha todos os campos")
		}

		


	});

	
	// function disabledBtn() {
	// 	btnFormContato.disabled = true
	// 	btnFormContato.classList.add("disabled")
	// }

	

	
	
}




