import { findAll } from "../utils/dom";

const Our_Services = () => {
   document.body.setAttribute("service_expand", '');

   const allServices = findAll('[js-service-id]');
   const totalServices = allServices.length;

   function reset_item_list(n_total, all_services, id_atual_active) {
       let i;

       for (i = 0; i < n_total; i++) {
           if (i !== parseInt(id_atual_active) - 1) {
               console.log(i)
               all_services[i].classList.remove('is_expand');
           }
       }
   }

   
	 if (window.matchMedia("(max-width: 768px)").matches) {
       
       if (allServices && allServices.length > 0) {
           allServices[0].classList.add('is_expand');
           document.body.setAttribute("service_expand", allServices[0].attributes['js-service-id'].value);
       }
   }

   if (allServices) {
       allServices.forEach((item) => {
           item.addEventListener("click", (e) => {
               e.target.classList.toggle('is_expand');

               if (e.target.classList.contains("is_expand")) {
                  document.body.setAttribute("service_expand", e.target.attributes['js-service-id'].value);
               }
               else {
                  document.body.setAttribute("service_expand", '');
               }

               reset_item_list(totalServices, allServices, document.body.attributes['service_expand'].value);

           })
       })
   }
}

export default Our_Services;
