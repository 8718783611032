import { findAll } from "../utils/dom";
import Swiper from "swiper"



const Our_socios = () => {



	var swiper = new Swiper('.swiper-container', {
   slidesPerView: 1,
   spaceBetween: 10,
   navigation: {
       nextEl: '.swiper-button-next',
       prevEl: '.swiper-button-prev',
   },
   pagination: {
       el: '.swiper-pagination',
       clickable: true,
   },
	 
		
	 
	 
});



// 	let xDown = null;                                                
// 	let yDown = null;


// 	function handleTouchStart(evt) {                                   
// 		xDown = evt.touches[0].clientX;                                
// 		yDown = evt.touches[0].clientY;                                
// 	}; 

// 	function handleTouchMove(evt) {
// 		if (!xDown || !yDown) {
// 				return;
// 		}
	
// 		let xUp = evt.touches[0].clientX;                               
// 		let yUp = evt.touches[0].clientY;
	
// 		let xDiff = xDown - xUp;
// 		let yDiff = yDown - yUp;
	
// 		if (Math.abs(xDiff) > Math.abs(yDiff)) {
// 				if (xDiff > 0) {
						
// 						console.log("swiped left")
// 						scrollRightmb()
						
// 				} else {
						
// 						console.log("swiped right")
					  
// 						scrollLeftmb()
// 				}                   
// 		} else {
// 				if (yDiff > 0) {
// 						/* up swipe */ 
// 						console.log("swiped up")
// 				} else {                                                                       
// 						/* down swipe */
// 						console.log("swiped down")
// 				}                                                            
// 		}
// 		/* reset values */
// 		xDown = null;
// 		yDown = null;                                       
// 	};

// 	let container = document.getElementById('container');
// 	container.addEventListener('touchstart', handleTouchStart, false);
// 	container.addEventListener('touchmove', handleTouchMove, false); 


// container.addEventListener('touchstart', handleTouchStart, false);
// container.addEventListener('touchmove', handleTouchMove, false); 

// 	const scrollLeftBtn = document.getElementById('scrollLeftBtn');
//   const scrollRightBtn = document.getElementById('scrollRightBtn');


// 	window.addEventListener('scroll', function() {
// 		var scrollX = window.scrollX;
// 		var totalWidth = document.body.scrollWidth;
	 
// 		var rectangle1 = document.getElementById('rectangle1');
// 		var rectangle2 = document.getElementById('rectangle2');
// 		var rectangle3 = document.getElementById('rectangle3');
	 
// 		if (scrollX > 0 && scrollX < totalWidth) {
// 			// O usuário está rolando para a direita
// 			rectangle1.classList.add('gb-primary-pure');
// 			rectangle2.classList.add('gb-primary-pure');
// 			rectangle3.classList.add('gb-primary-pure');
// 		} else {
// 			// O usuário está rolando para a esquerda
// 			rectangle1.classList.remove('gb-primary-pure');
// 			rectangle2.classList.remove('gb-primary-pure');
// 			rectangle3.classList.remove('gb-primary-pure');
// 		}
// 	 });

  
		


// 	document.getElementById('scrollLeftBtn').addEventListener('click', scrollLeft);
// 	document.getElementById('scrollRightBtn').addEventListener('click', scrollRight);
	


	

// function scrollLeft() {
//  const container = document.getElementById('container');
//  container.scrollLeft -= 290; 

 
//  if (container.scrollLeft <= 0) {

// 	document.getElementById('svgLeft').querySelector('use').setAttribute('xlink:href', '#button_circle_arrow_left');
// 	document.getElementById('svgRight').querySelector('use').setAttribute('xlink:href', '#button_circle_arrow');
// 	document.getElementById('svgRight').querySelector('use').setAttribute('transform', 'rotate(0, 20, 20)');
//  } else {
// 	document.getElementById('svgLeft').querySelector('use').setAttribute('xlink:href', '#button_circle_arrow_left');
 
//  document.getElementById('svgRight').querySelector('use').setAttribute('xlink:href', '#button_circle_arrow');
// 	document.getElementById('svgRight').querySelector('use').setAttribute('transform', 'rotate(0, 20, 20)');
 
 
 
//  }
// }

// function scrollLeftmb() {
// 	const container = document.getElementById('container');
// 	container.scrollLeft -= 190;
	
// 	window.scrollTo({
		
// 		behavior: 'smooth'
// });
 
	
// 	if (container.scrollLeft <= 0) {
 
// 	 document.getElementById('svgLeft').querySelector('use').setAttribute('xlink:href', '#button_circle_arrow_left');
// 	 document.getElementById('svgRight').querySelector('use').setAttribute('xlink:href', '#button_circle_arrow');
// 	 document.getElementById('svgRight').querySelector('use').setAttribute('transform', 'rotate(0, 20, 20)');
// 	} else {
// 	 document.getElementById('svgLeft').querySelector('use').setAttribute('xlink:href', '#button_circle_arrow_left');
	
// 	document.getElementById('svgRight').querySelector('use').setAttribute('xlink:href', '#button_circle_arrow');
// 	 document.getElementById('svgRight').querySelector('use').setAttribute('transform', 'rotate(0, 20, 20)');
	
	
	
// 	}
//  }

// function scrollRight() {
//  const container = document.getElementById('container');
//  container.scrollLeft += 290; 
 
//  if (container.scrollLeft + container.clientWidth >= container.scrollWidth) {
 
//  } else {
//  document.getElementById('svgRight').querySelector('use').setAttribute('xlink:href', '#button_circle_arrow_left');
//  document.getElementById('svgRight').querySelector('use').setAttribute('transform', 'rotate(180, 20, 20)');
//  document.getElementById('svgLeft').querySelector('use').setAttribute('xlink:href', '#button_circle_arrow2');
//  }

// 			let rectangle1 = document.getElementById('rectangle1');
// 			let rectangle1h = document.getElementById('rectangle1h');
// 			let rectangle2 = document.getElementById('rectangle2');
// 			let rectangle2n = document.getElementById('rectangle2n');
// 			let rectangle3 = document.getElementById('rectangle3');
// 			let rectangle3n = document.getElementById('rectangle3n');

		
	 
// 		if (container.scrollLeft > 10 && container.scrollLeft < 300) {
// 			// O usuário está rolando para a direita

// 			console.log(rectangle1)
// 			rectangle1.style.display = 'none';
//       rectangle1h.style.display = 'block';
//       rectangle2.style.display = 'none';
//       rectangle2n.style.display = 'block';
//       rectangle3.style.display = 'none';
//       rectangle3n.style.display = 'block';
// 		} else {
// 			// O usuário está rolando para a esquerda
// 			rectangle1.style.display = 'block';
//       rectangle1h.style.display = 'none';
//       rectangle2.style.display = 'block';
//       rectangle2n.style.display = 'none';
//       rectangle3.style.display = 'block';
//       rectangle3n.style.display = 'none';
// 		}






// }

// function scrollRightmb() {
// 	const container = document.getElementById('container');
// 	container.scrollLeft += 190; 

// 	window.scrollTo({
		
// 		behavior: 'smooth'
// 	});
	
// 	if (container.scrollLeft + container.clientWidth >= container.scrollWidth) {
	
// 	} else {
// 	document.getElementById('svgRight').querySelector('use').setAttribute('xlink:href', '#button_circle_arrow_left');
// 	document.getElementById('svgRight').querySelector('use').setAttribute('transform', 'rotate(180, 20, 20)');
// 	document.getElementById('svgLeft').querySelector('use').setAttribute('xlink:href', '#button_circle_arrow2');
// 	}
 
// 			 let rectangle1 = document.getElementById('rectangle1');
// 			 let rectangle1h = document.getElementById('rectangle1h');
// 			 let rectangle2 = document.getElementById('rectangle2');
// 			 let rectangle2n = document.getElementById('rectangle2n');
// 			 let rectangle3 = document.getElementById('rectangle3');
// 			 let rectangle3n = document.getElementById('rectangle3n');
 
		 
		
// 		 if (container.scrollLeft > 10 && container.scrollLeft < 300) {
// 			 // O usuário está rolando para a direita
 
// 			 console.log(rectangle1)
// 			 rectangle1.style.display = 'none';
// 			 rectangle1h.style.display = 'block';
// 			 rectangle2.style.display = 'none';
// 			 rectangle2n.style.display = 'block';
// 			 rectangle3.style.display = 'none';
// 			 rectangle3n.style.display = 'block';
// 		 } else {
// 			 // O usuário está rolando para a esquerda
// 			 rectangle1.style.display = 'block';
// 			 rectangle1h.style.display = 'none';
// 			 rectangle2.style.display = 'block';
// 			 rectangle2n.style.display = 'none';
// 			 rectangle3.style.display = 'block';
// 			 rectangle3n.style.display = 'none';
// 		 }
 
 
 
 
 
 
//  }


 

		



}









export default Our_socios;